/* eslint-disable no-undef */
import { Navigate, useRoutes } from 'react-router-dom';
import React from 'react';

// layouts
// import DashboardLayout from './layouts/dashboard';
// ----------------------------------------------------------------------
// eslint-disable-next-line no-undef
const DashboardLayout = React.lazy(() => import('./layouts/dashboard'));
const LogoOnlyLayout = React.lazy(() => import('./layouts/LogoOnlyLayout'));
const User = React.lazy(() => import('./pages/Users/User'));
const UserDetail = React.lazy(() => import('./pages/Users/UserDetail'));
const Register = React.lazy(() => import('./pages/Users/Register'));
const Login = React.lazy(() => import('./pages/Login'));
const NotFound = React.lazy(() => import('./pages/Page404'));
const Carts = React.lazy(() => import('./pages/PagoPA/Carts'));
const Companies = React.lazy(() => import('./pages/Companies/Companies'));
const Vehicles = React.lazy(() => import('./pages/Vehicles/Vehicles'));
const ListaIUV = React.lazy(() => import('./pages/PagoPA/ListaIUVs'));
const CartDetail = React.lazy(() => import('./pages/PagoPA/CartDetail'));
const Agende = React.lazy(() => import('./pages/Agende/MainCalendar'));
const Guide = React.lazy(() => import('./pages/Agende/Guide'));
const MessagesManager = React.lazy(() => import('./pages/Messages/MessagesManager'));
const MessageList = React.lazy(() => import('./pages/Messages/MessageList'));
const AllMessage = React.lazy(() => import('./pages/Messages/AllMessage'));
const Reports = React.lazy(() => import('./pages/PagoPA/Reports'));
const FormVersion = React.lazy(() => import('./pages/Modules/FormVersion'));
const LayoutEditor = React.lazy(() => import('./pages/Modules/LayoutEditor'));
const NewFormVersion = React.lazy(() => import('./pages/Modules/CreateFormVersion'));
const Home = React.lazy(() => import('./pages/Home'));
const FormList = React.lazy(() => import('./pages/Modules/FormList'));
const ActivityAssociate = React.lazy(() => import('./pages/Modules/ActivityAssociate'));
const Azienda = React.lazy(() => import('./pages/Impostazioni/Azienda'));
const Examiners = React.lazy(() => import('./pages/Examiners/Esaminatori'));
const ExaminerGroup = React.lazy(() => import('./pages/Examiners/ExaminerGroup'));
const Doctors = React.lazy(() => import('./pages/Doctors/Doctors'));
const MedicalVisit = React.lazy(() => import('./pages/Doctors/MedicalVisit'));
const MedicalVisitClienti = React.lazy(() => import('./pages/Doctors/MedicalVisitClienti'));
const Collaborators = React.lazy(() => import('./pages/Collaborators/Collaboratori'));
const CollaboratorDetail = React.lazy(() => import('./pages/Collaborators/CollaboratorDetail'));
const Titolari = React.lazy(() => import('./pages/Titolari/Titolari'));
const TitolariDetail = React.lazy(() => import('./pages/Titolari/TitolariDetail'));
const Autoscuole = React.lazy(() => import('./pages/Autoscuole/Autoscuole'));
const CommunicationManager = React.lazy(() => import('./pages/Communications/Communications'));
const CommunicationsCopy = React.lazy(() => import('./pages/Communications/CommunicationsCopy'));
const SoftwareLinks = React.lazy(() => import('./pages/SoftwareLinks/SoftwareLinks'));
const AutoscuolaDetail = React.lazy(() => import('./pages/Autoscuole/AutoscuolaDetail'));
const DatiFiscali = React.lazy(() => import('./pages/DatiFiscali/DatiFiscali'));
const DatoFiscaleDetail = React.lazy(() => import('./pages/DatiFiscali/DatoFiscaleDetail'));
const GruppoDetail = React.lazy(() => import('./pages/DatiFiscali/GruppoDetail'));
// const VehiclesDetail = React.lazy(() => import('./pages/Vehicles/VehiclesDetail'));
const Aule = React.lazy(() => import('./pages/Autoscuole/Aule'));
const Dottori = React.lazy(() => import('./pages/Autoscuole/Dottori'));
const RegistryAssociate = React.lazy(() => import('./pages/Autoscuole/RegistryAssociate'));
const ElencoPagoPA = React.lazy(() => import('./pages/Autoscuole/ElencoPagoPA'));
const Courses = React.lazy(() => import('./pages/Courses/Courses'));
const CourseDetail = React.lazy(() => import('./pages/Courses/CourseDetail'));
const CourseTemplates = React.lazy(() => import('./pages/Courses/CourseTemplates'));
const AssignedTopics = React.lazy(() => import('./pages/Courses/AssignedTopics'));
const PresenzeDetail = React.lazy(() => import('./pages/Courses/PresenzeDetail'));
const ControllaStatoPratiche = React.lazy(() => import('./pages/ServiziAvanzati/ControllaStatoPratiche'));
const ImportaAllievi = React.lazy(() => import('./pages/ServiziAvanzati/ImportaAllievi'));
const RitentaPagamentoPratiche = React.lazy(() => import('./pages/ServiziAvanzati/RitentaPagamentoPratiche'));
const RegistroGiornale = React.lazy(() => import('./pages/Registers/RegistroGiornale'));
const RegistroIscrizione = React.lazy(() => import('./pages/Registers/RegistroIscrizione'));
const SeduteEsame = React.lazy(() => import('./pages/GestioneEsami/SeduteEsame'));
const LocalitaEsame = React.lazy(() => import('./pages/GestioneEsami/LocalitaEsame'));
const Candidati = React.lazy(() => import('./pages/GestioneEsami/Candidati'));
const ScaricaSedute = React.lazy(() => import('./pages/GestioneEsami/ScaricaSedute'));
const ScaricaSeduta = React.lazy(() => import('./pages/GestioneEsami/ScaricaSeduta'));
const Settings = React.lazy(() => import('./pages/Impostazioni/Settings'));
const ActivityClosed = React.lazy(() => import('./pages/Users/ActivityClosed'));
const ActivityClosedUser = React.lazy(() => import('./pages/Users/ActivityClosedUser'));
const Statistiche = React.lazy(() => import('./pages/Reports/Statistiche'));
// const Turno = React.lazy(() => import('./pages/GestioneEsami/Turno'));
const StampaRegistro = React.lazy(() => import('./pages/Registers/StampaRegistro'));
const Agency = React.lazy(() => import('./pages/Agency/Agency'));
const Fornitore = React.lazy(() => import('./pages/Agency/Fornitore'));
const AgencyDetail = React.lazy(() => import('./pages/Agency/AgencyDetail'));
const FornitoreDetail = React.lazy(() => import('./pages/Agency/FornitoreDetail'));
const FiscalServiceCategory = React.lazy(() => import('./pages/Fiscal/FiscalServiceCategory'));
const FiscalServiceLicense = React.lazy(() => import('./pages/Fiscal/FiscalServiceLicense'));
const FiscalCodiceIva = React.lazy(() => import('./pages/Fiscal/FiscalCodiceIva'));
const FiscalPaymentType = React.lazy(() => import('./pages/Fiscal/FiscalPaymentType'));
const FiscalAdvancePayment = React.lazy(() => import('./pages/Fiscal/FiscalAdvancePayment'));
const FiscalAnticipazioniGrupo = React.lazy(() => import('./pages/Fiscal/FiscalAnticipazioniGrupo'));
const FiscalPriceListType = React.lazy(() => import('./pages/Fiscal/FiscalPriceListType'));
const FiscalService = React.lazy(() => import('./pages/Fiscal/FiscalService'));
const FiscalCorrispettiviMensili = React.lazy(() => import('./pages/Fiscal/FiscalCorrispettiviMensili'));
const FiscalTypeListGrupo = React.lazy(() => import('./pages/Fiscal/FiscalTypeListGrupo'));
const Payments = React.lazy(() => import('./pages/Payment/Payments'));
const ListFiscalPayments = React.lazy(() => import('./pages/Payment/ListFiscalPayments'));
const ListinoServizi = React.lazy(() => import('./pages/Fiscal/ListinoServizi'));
const FiscalArticleForUser = React.lazy(() => import('./pages/Fiscal/FiscalArticleForUser'));
const FiscalCashSheet = React.lazy(() => import('./pages/Fiscal/FiscalCashSheet'));
// const FiscalChartAccount = React.lazy(() => import('./pages/Fiscal/FiscalChartAccount'));
const PianoDeiConti = React.lazy(() => import('./pages/PianoDeiConti/PianoDeiConti'));
const Invoice = React.lazy(() => import('./pages/Billing/Invoice'));
const ElencoNazione = React.lazy(() => import('./pages/Nazione/ElencoNazione'));
const ElencoCitta = React.lazy(() => import('./pages/City/ElencoCitta'));
// const Pagamento = React.lazy(() => import('./pages/Pagamenti/Pagamento'));
const Entrata = React.lazy(() => import('./pages/Pagamenti/Entrata'));
const Uscita = React.lazy(() => import('./pages/Pagamenti/Uscita'));
const Profile = React.lazy(() => import('./pages/Profile'));
const ElencoIscritti = React.lazy(() => import('./pages/ElencoIscritti/ElencoIscritti'));

// Sample
const TextSample = React.lazy(() => import('./pages/Components/TextSample'));
const TextareaSample = React.lazy(() => import('./pages/Components/TextareaSample'));
const SelectSample = React.lazy(() => import('./pages/Components/SelectSample'));
const FormSample = React.lazy(() => import('./pages/Components/FormSample'));
const DateSample = React.lazy(() => import('./pages/Components/DateSample'));
const WorkInProgress = React.lazy(() => import('./pages/Components/WorkInProgress'));

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element:
        <React.Suspense fallback={<>...</>}>
          <DashboardLayout />
        </React.Suspense>,
      children: [
        {
          path: 'home', element:
            <React.Suspense fallback={<>...</>}>
              <Home />
            </React.Suspense>
        },
        {
          path: 'user', element:
            <React.Suspense fallback={<>...</>}>
              <User />
            </React.Suspense>
        },
        {
          path: 'user/:id', element:
            <React.Suspense fallback={<>...</>}>
              <UserDetail />
            </React.Suspense>
        },
        {
          path: 'user/:id/activity/:companyId/', element:
            <React.Suspense fallback={<>...</>}>
              <ActivityClosed />
            </React.Suspense>
        },
        {
          path: 'user/:id/activityclosed/:id', element:
            <React.Suspense fallback={<>...</>}>
              <ActivityClosedUser />
            </React.Suspense>
        },
        {
          path: 'user/:id/messages',
          element:
            <React.Suspense fallback={<>...</>}>
              <MessageList />
            </React.Suspense>
        },
        {
          path: 'pagopa/reports', element:
            <React.Suspense fallback={<>...</>}>
              <Reports />
            </React.Suspense>
        },
        {
          path: 'module', element:
            <React.Suspense fallback={<>...</>}>
              <FormList />
            </React.Suspense>
        },
        {
          path: 'module/activitylinked/:id', element:
            <React.Suspense fallback={<>...</>}>
              <ActivityAssociate />
            </React.Suspense>
        },
        {
          path: 'module/:formId', element:
            <React.Suspense fallback={<>...</>}>
              <FormVersion />
            </React.Suspense>
        },
        {
          path: 'module/:formId/new', element:
            <React.Suspense fallback={<>...</>}>
              <NewFormVersion />
            </React.Suspense>
        },
        {
          path: 'module/:formId/:formVersionId', element:
            <React.Suspense fallback={<>...</>}>
              <LayoutEditor />
            </React.Suspense>
        },
        {
          path: 'Impostazioni/Azienda',
          element:
            <React.Suspense fallback={<>...</>}>
              <Azienda />
            </React.Suspense>
        },
        {
          path: 'communication', element:
            <React.Suspense fallback={<>...</>}>
              <CommunicationManager />
            </React.Suspense>
        },
        {
          path: 'comunicazione-addizione', element:
            <React.Suspense fallback={<>...</>}>
              <CommunicationsCopy />
            </React.Suspense>
        },
        {
          path: 'software-links', element:
            <React.Suspense fallback={<>...</>}>
              <SoftwareLinks />
            </React.Suspense>
        },
        {
          path: 'profile', element:
            <React.Suspense fallback={<>...</>}>
              <Profile />
            </React.Suspense>
        },
        {
          path: 'Impostazioni/preferenze-di-sistema',
          element:
            <React.Suspense fallback={<>...</>}>
              <Settings />
            </React.Suspense>
        },
        {
          path: 'pagopa/carts',
          element:
            <React.Suspense fallback={<>...</>}>
              <Carts />
            </React.Suspense>
        },
        {
          path: 'companies',
          element:
            <React.Suspense fallback={<>...</>}>
              <Companies />
            </React.Suspense>
        },
        {
          path: 'elenco-autoscuole', element:
            <React.Suspense fallback={<>...</>}>
              <Autoscuole />
            </React.Suspense>
        },
        {
          path: 'elenco-autoscuole/:id', element:
            <React.Suspense fallback={<>...</>}>
              <AutoscuolaDetail />
            </React.Suspense>
        },
        {
          path: 'elenco-autoscuole/registrylinked/:id', element:
            <React.Suspense fallback={<>...</>}>
              <RegistryAssociate />
            </React.Suspense>
        },
        {
          path: 'elenco-aule/:id', element:
            <React.Suspense fallback={<>...</>}>
              <Aule />
            </React.Suspense>
        },
        {
          path: 'dati-fiscali', element:
            <React.Suspense fallback={<>...</>}>
              <DatiFiscali />
            </React.Suspense>
        },
        {
          path: 'dati-fiscali/:id', element:
            <React.Suspense fallback={<>...</>}>
              <DatoFiscaleDetail />
            </React.Suspense>
        },
        {
          path: 'gruppo/:id', element:
            <React.Suspense fallback={<>...</>}>
              <GruppoDetail />
            </React.Suspense>
        },
        {
          path: 'dottori/:id', element:
            <React.Suspense fallback={<>...</>}>
              <Dottori />
            </React.Suspense>
        },
        {
          path: 'vehicles',
          element:
            <React.Suspense fallback={<>...</>}>
              <Vehicles />
            </React.Suspense>
        },
        {
          path: 'vehicles/registrylinked/:id', element:
            <React.Suspense fallback={<>...</>}>
              <RegistryAssociate />
            </React.Suspense>
        },
        {
          path: 'reports',
          element:
            <React.Suspense fallback={<>...</>}>
              <Statistiche />
            </React.Suspense>
        },
        {
          path: 'elenco-pagopa/:id', element:
            <React.Suspense fallback={<>...</>}>
              <ElencoPagoPA />
            </React.Suspense>
        },
        {
          path: 'pagopa/carts/new',
          element:
            <React.Suspense fallback={<>...</>}>
              <CartDetail />
            </React.Suspense>
        },
        {
          path: 'pagopa/carts/:id',
          element:
            <React.Suspense fallback={<>...</>}>
              <CartDetail />
            </React.Suspense>
        },
        {
          path: 'agende/:type',
          element:
            <React.Suspense fallback={<>...</>}>
              <Agende />
            </React.Suspense>
        },
        {
          path: 'agende/:type/:id',
          element:
            <React.Suspense fallback={<>...</>}>
              <Guide />
            </React.Suspense>
        },
        {
          path: 'pagopa/iuv/:id',
          element:
            <React.Suspense fallback={<>...</>}>
              <ListaIUV />
            </React.Suspense>
        },
        {
          path: 'gestione-esami/elenco-esaminatori', element:
            <React.Suspense fallback={<>...</>}>
              <Examiners />
            </React.Suspense>
        },
        {
          path: 'gestione-esami/elenco-esaminatori-gruppo', element:
            <React.Suspense fallback={<>...</>}>
              <ExaminerGroup />
            </React.Suspense>
        },
        {
          path: 'gestione-esami/sedute-esame', element:
            <React.Suspense fallback={<>...</>}>
              <SeduteEsame />
            </React.Suspense>
        },
        {
          path: 'gestione-esami/scarica-sedute', element:
            <React.Suspense fallback={<>...</>}>
              <ScaricaSedute />
            </React.Suspense>
        },
        {
          path: 'gestione-esami/scarica-seduta/:id', element:
            <React.Suspense fallback={<>...</>}>
              <ScaricaSeduta />
            </React.Suspense>
        },
        {
          path: 'gestione-esami/esame/:id', element:
            <React.Suspense fallback={<>...</>}>
              <Candidati />
            </React.Suspense>
        },
        {
          path: 'gestione-esami/elenco-test/:id', element:
            <React.Suspense fallback={<>...</>}>
              <LocalitaEsame />
            </React.Suspense>
        },
        {
          path: 'registri/registro-giornale', element:
            <React.Suspense fallback={<>...</>}>
              <RegistroGiornale />
            </React.Suspense>
        },
        {
          path: 'registri/registro-iscrizione', element:
            <React.Suspense fallback={<>...</>}>
              <RegistroIscrizione />
            </React.Suspense>
        },
        {
          path: 'registri/stampa-registro/:id', element:
            <React.Suspense fallback={<>...</>}>
              <StampaRegistro />
            </React.Suspense>
        },
        {
          path: 'visite-mediche/gestione-appuntamenti', element:
            <React.Suspense fallback={<>...</>}>
              <MedicalVisit />
            </React.Suspense>
        },
        {
          path: 'visite-mediche/dottori', element:
            <React.Suspense fallback={<>...</>}>
              <Doctors />
            </React.Suspense>
        },
        {
          path: 'visite-mediche/gestione-clienti/:id', element:
            <React.Suspense fallback={<>...</>}>
              <MedicalVisitClienti />
            </React.Suspense>
        },
        {
          path: 'gestione-corsi/recupero-punti', element:
            <React.Suspense fallback={<>...</>}>
              <Courses />
            </React.Suspense>
        },
        {
          path: 'gestione-corsi/recupero-punti/:id', element:
            <React.Suspense fallback={<>...</>}>
              <CourseDetail />
            </React.Suspense>
        },
        {
          path: 'gestione-corsi/recupero-punti-presenze/:id', element:
            <React.Suspense fallback={<>...</>}>
              <PresenzeDetail />
            </React.Suspense>
        },
        {
          path: 'course-templates', element:
            <React.Suspense fallback={<>...</>}>
              <CourseTemplates />
            </React.Suspense>
        },
        {
          path: 'course-templates/assignedtopics/:id', element:
            <React.Suspense fallback={<>...</>}>
              <AssignedTopics />
            </React.Suspense>
        },
        {
          path: 'servizi-avanzati-portale/controlla-stato-pratiche', element:
            <React.Suspense fallback={<>...</>}>
              <ControllaStatoPratiche />
            </React.Suspense>
        },
        {
          path: 'servizi-avanzati-portale/importa-allievi', element:
            <React.Suspense fallback={<>...</>}>
              <ImportaAllievi />
            </React.Suspense>
        },
        {
          path: 'servizi-avanzati-portale/ritenta-pagamento-pratiche', element:
            <React.Suspense fallback={<>...</>}>
              <RitentaPagamentoPratiche />
            </React.Suspense>
        },
        {
          path: 'fiscalita/categorie-servizi', element:
            <React.Suspense fallback={<>...</>}>
              <FiscalServiceCategory />
            </React.Suspense>
        },
        {
          path: 'fiscalita/patenti-servizi', element:
            <React.Suspense fallback={<>...</>}>
              <FiscalServiceLicense />
            </React.Suspense>
        },
        {
          path: 'fiscalita/codici-iva', element:
            <React.Suspense fallback={<>...</>}>
              <FiscalCodiceIva />
            </React.Suspense>
        },
        {
          path: 'fiscalita/tipi-di-pagamento', element:
            <React.Suspense fallback={<>...</>}>
              <FiscalPaymentType />
            </React.Suspense>
        },
        {
          path: 'fiscalita/anticipazioni', element:
            <React.Suspense fallback={<>...</>}>
              <FiscalAdvancePayment />
            </React.Suspense>
        },
        {
          path: 'fiscalita/anticipazioni-personalizzazione', element:
            <React.Suspense fallback={<>...</>}>
              <FiscalAnticipazioniGrupo />
            </React.Suspense>
        },
        {
          path: 'fiscalita/tipi-listini', element:
            <React.Suspense fallback={<>...</>}>
              <FiscalPriceListType />
            </React.Suspense>
        },
        {
          path: 'fiscalita/listino-servizi', element:
            <React.Suspense fallback={<>...</>}>
              <ListinoServizi />
            </React.Suspense>
        },
        {
          path: 'fiscalita/servizi', element:
            <React.Suspense fallback={<>...</>}>
              <FiscalService />
            </React.Suspense>
        },
        {
          path: 'fiscalita/corrispettivi-mensili', element:
            <React.Suspense fallback={<>...</>}>
              <FiscalCorrispettiviMensili />
            </React.Suspense>
        },
        {
          path: 'fiscalita/tipi-listini-personalizzazione', element:
            <React.Suspense fallback={<>...</>}>
              <FiscalTypeListGrupo />
            </React.Suspense>
        },
        {
          path: 'fiscalita/scontrino', element:
            <React.Suspense fallback={<>...</>}>
              <Payments />
            </React.Suspense>
        },
        {
          path: 'fiscalita/scontrino/:id_article', element:
            <React.Suspense fallback={<>...</>}>
              <Payments />
            </React.Suspense>
        },
        {
          path: 'fiscalita/billing', element:
            <React.Suspense fallback={<>...</>}>
              <Invoice />
            </React.Suspense>
        },
        {
          path: 'fiscalita/billing/:id_article', element:
            <React.Suspense fallback={<>...</>}>
              <Invoice />
            </React.Suspense>
        },
        {
          path: 'fiscalita/pagamento/collection', element:
            <React.Suspense fallback={<>...</>}>
              <Entrata />
            </React.Suspense>
        },
        {
          path: 'fiscalita/pagamento/collection/:id_payment', element:
            <React.Suspense fallback={<>...</>}>
              <Entrata />
            </React.Suspense>
        },
        {
          path: 'fiscalita/pagamento/expenditure', element:
            <React.Suspense fallback={<>...</>}>
              <Uscita />
            </React.Suspense>
        },
        {
          path: 'fiscalita/pagamento/expenditure/:id_payment', element:
            <React.Suspense fallback={<>...</>}>
              <Uscita />
            </React.Suspense>
        },
        // {
        //   path: 'fiscalita/pagamento/:type', element:
        //     <React.Suspense fallback={<>...</>}>
        //       <Pagamento />
        //     </React.Suspense>
        // },
        // {
        //   path: 'fiscalita/pagamento/:type/:id_company', element:
        //     <React.Suspense fallback={<>...</>}>
        //       <Pagamento />
        //     </React.Suspense>
        // },
        {
          path: 'fiscalita/pagamenti', element:
            <React.Suspense fallback={<>...</>}>
              <ListFiscalPayments />
            </React.Suspense>
        },
        {
          path: 'fiscalita/piano-dei-conti', element:
            <React.Suspense fallback={<>...</>}>
              <PianoDeiConti />
            </React.Suspense>
        },
        {
          path: 'fiscalita/scheda-per-utente/:id', element:
            <React.Suspense fallback={<>...</>}>
              <FiscalArticleForUser />
            </React.Suspense>
        },
        {
          path: 'fiscalita/foglio-cassa', element:
            <React.Suspense fallback={<>...</>}>
              <FiscalCashSheet />
            </React.Suspense>
        },
        {
          path: 'amministrazione/collaboratori', element:
            <React.Suspense fallback={<>...</>}>
              <Collaborators />
            </React.Suspense>
        },
        {
          path: 'amministrazione/collaboratori/:id', element:
            <React.Suspense fallback={<>...</>}>
              <CollaboratorDetail />
            </React.Suspense>
        },
        {
          path: '/dashboard/agency/', element:
            <React.Suspense fallback={<>...</>}>
              <Agency />
            </React.Suspense>
        },
        {
          path: '/dashboard/fornitore/', element:
            <React.Suspense fallback={<>...</>}>
              <Fornitore />
            </React.Suspense>
        },
        {
          path: '/dashboard/agency/:id', element:
            <React.Suspense fallback={<>...</>}>
              <AgencyDetail />
            </React.Suspense>
        },
        {
          path: '/dashboard/fornitore/:id', element:
            <React.Suspense fallback={<>...</>}>
              <FornitoreDetail />
            </React.Suspense>
        },
        {
          path: '/dashboard/titolari', element:
            <React.Suspense fallback={<>...</>}>
              <Titolari />
            </React.Suspense>
        },
        {
          path: '/dashboard/titolari/:id', element:
            <React.Suspense fallback={<>...</>}>
              <TitolariDetail />
            </React.Suspense>
        },
        {
          path: '/dashboard/elenco-nazione', element:
            <React.Suspense fallback={<>...</>}>
              <ElencoNazione />
            </React.Suspense>
        },
        {
          path: '/dashboard/elenco-iscritti', element:
            <React.Suspense fallback={<>...</>}>
              <ElencoIscritti />
            </React.Suspense>
        },
        {
          path: '/dashboard/elenco-citta', element:
            <React.Suspense fallback={<>...</>}>
              <ElencoCitta />
            </React.Suspense>
        },
        {
          path: 'Messaggistica',
          element:
            <React.Suspense fallback={<>...</>}>
              <MessagesManager />
            </React.Suspense>
        },
        {
          path: 'all_message',
          element:
            <React.Suspense fallback={<>...</>}>
              <AllMessage />
            </React.Suspense>
        },
        {
          path: '*',
          element:
            <React.Suspense fallback={<>...</>}>
              <Navigate to="/404" />
            </React.Suspense>
        },

        // Components
        {
          path: 'Components/Text',
          element:
            <React.Suspense fallback={<>...</>}>
              <TextSample />
            </React.Suspense>
        },
        {
          path: 'Components/Textarea',
          element:
            <React.Suspense fallback={<>...</>}>
              <TextareaSample />
            </React.Suspense>
        },
        {
          path: 'Components/Select',
          element:
            <React.Suspense fallback={<>...</>}>
              <SelectSample />
            </React.Suspense>
        },
        {
          path: 'Components/Form',
          element:
            <React.Suspense fallback={<>...</>}>
              <FormSample />
            </React.Suspense>
        },
        {
          path: 'Components/Date',
          element:
            <React.Suspense fallback={<>...</>}>
              <DateSample />
            </React.Suspense>
        },
        {
          path: 'Components/WorkInProgress',
          element:
            <React.Suspense fallback={<>...</>}>
              <WorkInProgress />
            </React.Suspense>
        },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: '/', element:
            <React.Suspense fallback={<>...</>}>
              <Navigate to="/dashboard/home" />
            </React.Suspense>
        },
        {
          path: 'login', element:
            <React.Suspense fallback={<>...</>}>
              <Login />
            </React.Suspense>
        },
        {
          path: 'register', element:
            <React.Suspense fallback={<>...</>}>
              <Register />
            </React.Suspense>
        },
        {
          path: '404', element:
            <React.Suspense fallback={<>...</>}>
              <NotFound />
            </React.Suspense>
        },
        {
          path: '*',
          element:
            <React.Suspense fallback={<>...</>}>
              <Navigate to="/404" />
            </React.Suspense>
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
